import {
  Button,
  FormControlSizes,
  LoaderTalos,
  isTalosUser,
  logger,
  useCurrenciesContext,
  useMarketAccountsContext,
  useMarketsContext,
  useOrganizationContext,
  useSecuritiesContext,
  useStrategiesContext,
  useUnifiedLiquidityContext,
  useUserContext,
  type User,
} from '@talos/kyoko';
import { LoaderWrapper } from 'components/Loader';
import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { changeOrganization, unmorph } from 'utils/admin';

import { useOrgConfiguration } from './OrgConfigurationProvider';
import { useSubAccounts } from './SubAccountsContext';
import { useUsersContext } from './UsersContext';

const AdminHeader = lazy(() => import('components/AdminHeader'));

export const ProvidersGate = ({ children }) => {
  const { isLoaded: isMarketsReady } = useMarketsContext();
  const { isLoaded: isCurrenciesReady } = useCurrenciesContext();
  const { isLoaded: isSecuritiesReady } = useSecuritiesContext();
  const { isLoaded: isMarketAccountsReady } = useMarketAccountsContext();
  const { isLoaded: isSubAccountsReady } = useSubAccounts();
  const { isLoaded: isUsersReady } = useUsersContext();
  const { isLoaded: isStrategiesReady } = useStrategiesContext();
  const { isLoaded: isOrganizationReady } = useOrganizationContext();
  const { isLoaded: isUnifiedLiquidityReady } = useUnifiedLiquidityContext();
  const { isLoaded: isOrgConfigurationLoaded } = useOrgConfiguration();
  const [providersReady, setProvidersReady] = useState(false);
  const [disconnected, setDisconnected] = useState(false);

  const { user } = useUserContext() as { user: User };

  useEffect(() => {
    setProvidersReady(
      [
        isMarketsReady,
        isCurrenciesReady,
        isSecuritiesReady,
        isMarketAccountsReady,
        isSubAccountsReady,
        isUsersReady,
        isStrategiesReady,
        isOrganizationReady,
        isUnifiedLiquidityReady,
        isOrgConfigurationLoaded,
      ].every(item => item === true)
    );
  }, [
    isMarketsReady,
    isCurrenciesReady,
    isSecuritiesReady,
    isMarketAccountsReady,
    isSubAccountsReady,
    isUsersReady,
    isStrategiesReady,
    isOrganizationReady,
    isUnifiedLiquidityReady,
    isOrgConfigurationLoaded,
  ]);

  useEffect(() => {
    const ready = providersReady;
    if (ready) {
      logger.info('[pg] Providers are ready');
    }
    const timeout = setTimeout(() => {
      setDisconnected(!ready);
      if (ready) {
        logger.warn('[pg] Providers not ready within timeout');
      }
    }, 2 * 60 * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [providersReady]);

  const handleUnmorph = useCallback(() => {
    if (user.IsMorphed) {
      unmorph().then(() => (window.location.href = '/'));
    } else if (user.UsingOrgID) {
      changeOrganization(user.OrgID).then(() => (window.location.href = '/'));
    }
  }, [user]);

  if (disconnected) {
    return (
      <LoaderWrapper>
        <p>Disconnected!</p>
        {(user?.IsMorphed || user?.UsingOrgID) && (
          <Button size={FormControlSizes.Small} onClick={handleUnmorph}>
            Unmorph
          </Button>
        )}
      </LoaderWrapper>
    );
  }
  const showAdminHeader = user.IsMorphed || isTalosUser(user);

  return (
    <>
      {/* Admin Header must be accessible to Talos users even if essential providers cannot load.  */}
      {/* UserContextProvider is guaranteed to resolve by this point. */}
      {showAdminHeader && (
        <Suspense fallback={<div>Loading admin header</div>}>
          <AdminHeader />
        </Suspense>
      )}
      {providersReady ? children : <LoaderTalos />}
    </>
  );
};
