import { BlotterTable, Box, LoaderSizes, logger, useDynamicCallback, type Security } from '@talos/kyoko';
import type { CellValueChangedEvent, GetRowIdParams, GridReadyEvent } from 'ag-grid-community';
import { useAppStateDispatch, useAppStateSelector } from 'providers/AppStateProvider';
import type { AppState } from 'providers/AppStateProvider/types';
import { useMemo, useRef } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import { Loader, LoaderWrapper } from '../Loader';
import {
  selectCustomLegsAsRows,
  selectIsEntryMode,
  selectIsReferenceDataReady,
  updateCustomLegs,
} from './MultilegComboSlice';
import { AgMarketAccountRenderer } from './components/AgMarketAccountRenderer';
import { AgMarketAccountSelector } from './components/AgMarketAccountSelector';
import { AgSymbolSelector } from './components/AgSymbolSelector';
import { DEFAULT_COLUMN_DEFINITION, getDelta1ColDef } from './delta1ColDef';
import type { CustomLegRow, OptionStrategyRow } from './types';

export const Delta1SpreadScreen = ({ panelID }: { panelID: string }) => {
  const theme = useTheme();
  const dispatch = useAppStateDispatch();

  const customLegs = useAppStateSelector((state: AppState) => state.multilegCombo.panels[panelID]?.customLegs);

  const rowData: CustomLegRow[] = useAppStateSelector(state => selectCustomLegsAsRows(state, panelID));
  const isReferenceDataReady = useAppStateSelector(selectIsReferenceDataReady);

  const isEntryMode = useAppStateSelector(state => selectIsEntryMode(state, panelID));

  const context = useRef<{ theme: DefaultTheme }>({ theme });

  const handleCellValueChanged = useDynamicCallback((ev: CellValueChangedEvent) => {
    const field = ev.colDef.field;

    if (!field || ev.rowIndex == null || !customLegs) {
      return;
    }

    switch (field) {
      case 'productType':
        dispatch(
          updateCustomLegs({ panelID, customLegs: customLegs.updateProductTypeForLeg(ev.newValue, ev.rowIndex) })
        );
        break;
      case 'symbol':
        dispatch(updateCustomLegs({ panelID, customLegs: customLegs.updateSymbol(ev.newValue, ev.rowIndex) }));
        break;
      case 'exchange':
        dispatch(updateCustomLegs({ panelID, customLegs: customLegs.updateExchange(ev.newValue, ev.rowIndex) }));
        break;
      case 'markets':
        dispatch(
          updateCustomLegs({
            panelID,
            customLegs: customLegs.updateMarketAccounts(ev.newValue, ev.rowIndex),
            isEditableParam: true,
          })
        );
        break;
      case 'expiry':
        dispatch(updateCustomLegs({ panelID, customLegs: customLegs.updateExpiry(ev.newValue, ev.rowIndex) }));
        break;
      case 'strike':
        dispatch(updateCustomLegs({ panelID, customLegs: customLegs.updateStrike(ev.newValue, ev.rowIndex) }));
        break;
      case 'type':
        dispatch(updateCustomLegs({ panelID, customLegs: customLegs.updateType(ev.newValue, ev.rowIndex) }));
        break;
      case 'initiating':
        dispatch(
          updateCustomLegs({
            panelID,
            customLegs: customLegs.updateInitiatingLegs(ev.newValue, ev.rowIndex),
            isEditableParam: true,
          })
        );
        break;

      default:
        logger.error(new Error(`Invalid modification on MultilegCombo, field: ${field}`));
    }
  });

  const handleSecurityChanged = useDynamicCallback((security: Security, rowIndex: number) => {
    if (customLegs && security) {
      dispatch(updateCustomLegs({ panelID, customLegs: customLegs.updateSecurityForLeg(security, rowIndex) }));
    }
  });

  const columnDefs = useMemo(
    () => getDelta1ColDef(handleSecurityChanged, customLegs, isEntryMode),
    [customLegs, handleSecurityChanged, isEntryMode]
  );

  if (!isReferenceDataReady) {
    return (
      <LoaderWrapper>
        <Loader size={LoaderSizes.SMALL} />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Box minHeight={110} h={110} data-testid="delta1-wrapper" id="multileg-deepdive-blotter-wrapper">
        <BlotterTable
          gridOptions={{
            // @ts-expect-error  We don't allow context to have just the required data
            context,
            getRowId: (params: GetRowIdParams<OptionStrategyRow>) => params.data.id,
            defaultColDef: DEFAULT_COLUMN_DEFINITION,
            columnDefs,
            singleClickEdit: true,
            stopEditingWhenCellsLoseFocus: true,
            rowData,
            rowHeight: 34,
            onCellValueChanged: handleCellValueChanged,
            onGridReady: (event: GridReadyEvent) => {
              event.api.sizeColumnsToFit();
            },
            suppressContextMenu: true,
          }}
          extraComponents={{
            symbolSelector: AgSymbolSelector,
            marketAccountSelector: AgMarketAccountSelector,
            marketAccountRenderer: AgMarketAccountRenderer,
          }}
        />
      </Box>
    </>
  );
};
